import React from "react";
import SocialNav from "./nav/SocialNav";

const Socials = () => {
  return (
    <li className="flex items-center gap-2">
      <SocialNav
        to={"https://wa.me/message/CP6DSNCGCRW5B1"}
        icon={"whatsapp"}
      />
      <SocialNav
        to={"https://www.instagram.com/studiogloo"}
        icon={"instagram"}
      />
    </li>
  );
};

export default Socials;
