import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div data-gsap="logo" className="absolute left-1/2 mt-4 -translate-x-1/2">
      <Link to={"/"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="GLOO"
          className="h-10 overflow-visible relative z-10"
          viewBox="0 0 188.88 98.11"
        >
          <path
            className="scale-[3] translate-x-1 -translate-y-28"
            data-gsap="logo-g"
            fill="#f9f9f9"
            d="m26.64,98.11c-7.86,0-13.72-1.97-17.93-4.9-8.83-6.25-5.67-20.12,4.9-22.32-.04.42-.08,2.24-.08,3.82,0,.96,0,1.85.08,2.31.27,2.24,1.5,7.83,13.03,7.83s12.95-5.51,12.95-7.83c0-1.93-1.43-7.83-12.95-7.83-14.76,0-25.98-10.37-26.64-24.32v-.62c-.04-.23-.04-.46-.04-.66C-.04,28.99,11.45,17.97,26.64,17.97c1.73,0,3.43.15,5.05.42.27.04.54.08.85.12.19.04.42.08.62.08,5.24.5,8.48-1.27,10.99-4.12l4.43,3.39c3.35,2.62,2.81,7.83-.96,9.75-5.47,2.81-12.45,3.28-20.97,3.28-7.71,0-13.76,4.74-13.76,12.72s6.05,13.22,13.76,13.22,13.72-5.28,13.72-13.22l-.04-5.55c0-2.39,1.58-4.43,3.85-5.09,2-.54,3.89-1.27,5.71-2.27,2.2,3.74,3.39,8.13,3.39,12.91v.66c-.19,7.25-3.82,13.45-9.56,17.54.42.19.85.46,1.27.69,3.89,2.31,7.05,5.9,8.13,11.22,1.66,9.48-4.78,24.4-26.48,24.4Z"
          />
          <path
            className="opacity-0"
            data-gsap="logo-l"
            fill="#f9f9f9"
            d="m57.48,56.75V0h0c7.39,0,13.37,5.99,13.37,13.37v56.75h0c-7.39,0-13.37-5.99-13.37-13.37Z"
          />
          <path
            className="opacity-0"
            data-gsap="logo-o-1"
            fill="#f9f9f9"
            d="m122.29,26.62c-5.33-4.72-11.73-7.01-19.52-7.01s-14.48,2.21-19.97,6.77c-5.29,4.43-7.87,10.62-7.87,18.99s2.54,14.19,7.71,18.66c5.37,4.63,11.93,6.85,20.14,6.85s14.44-2.38,19.64-7.22c5.17-4.8,7.63-10.79,7.63-18.29,0-8-2.54-14.15-7.75-18.74Zm-5.02,18.74c-.04,5.39-2.74,12.74-14.45,12.74-10.64,0-15.05-6.37-15.09-12.74v-.13c.04-6.37,4.45-12.74,15.09-12.74,11.71,0,14.41,7.35,14.45,12.74v.13Z"
          />
          <path
            className="opacity-0"
            data-gsap="logo-o-2"
            fill="#f9f9f9"
            d="m181.13,26.62c-5.33-4.72-11.73-7.01-19.52-7.01s-14.48,2.21-19.97,6.77c-5.29,4.43-7.87,10.62-7.87,18.99s2.54,14.19,7.71,18.66c5.37,4.63,11.93,6.85,20.14,6.85s14.44-2.38,19.64-7.22c5.17-4.8,7.63-10.79,7.63-18.29,0-8-2.54-14.15-7.75-18.74Zm-5.02,18.74c-.04,5.39-2.74,12.74-14.45,12.74-10.64,0-15.05-6.37-15.09-12.74v-.13c.04-6.37,4.45-12.74,15.09-12.74,11.71,0,14.41,7.35,14.45,12.74v.13Z"
          />
        </svg>
        <div className="relative">
          <div className="bg-theme-color--brand-red-contrast h-px mt-4 w-full"></div>
          <div
            data-gsap="line-top-reveal"
            className="absolute bg-theme-color--brand-red inset-0"
          ></div>
        </div>
      </Link>
    </div>
  );
};

export default Logo;
