import React from "react";
import Icon from "../../icon/Icon";

const SocialNav = ({ to, icon }) => {
  return (
    <div
      data-gsap="social-network"
      className="opacity-0 translate-x-2 -translate-y-4"
    >
      <a
        href={to}
        className="bg-theme-color--brand-red-contrast border-white flex h-10 items-center justify-center text-2xl text-theme-color--brand-red rounded-full w-10"
      >
        <Icon icon={icon} iconStyle={"brands"} />
      </a>
    </div>
  );
};

export default SocialNav;
