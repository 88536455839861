import React, { useContext, useEffect } from "react";
import Heading from "../components/app/typography/Heading";
import { PageContext } from "../contexts/PageContextProvider";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const pageContext = useContext(PageContext);
  const gsapTL = pageContext.gsapTL;
  useEffect(() => {
    if (gsapTL) {
      gsapTL
        .to("[data-gsap='heading-reveal']", {
          translateX: "100%",
          duration: 1.5,
          stagger: 0.5,
        })
        .to("[data-gsap='line-bottom-reveal']", {
          translateX: "100%",
          delay: -0.5,
        })
        .to("[data-gsap='link-mail']", { opacity: 1, translateY: 0 });
    }
  }, [gsapTL, location.pathname]);
  return (
    <section className="flex flex-col min-h-theme-height--svh-header items-start justify-end px-10 py-12 md:justify-center md:text-center">
      <div className="mx-auto max-w-2xl w-full">
        <Heading>
          <div className="relative">
            <span>web</span>
            <div
              data-gsap="heading-reveal"
              className="absolute bg-theme-color--brand-red inset-0"
            ></div>
          </div>
          <div className="relative">
            <span>coming</span>
            <div
              data-gsap="heading-reveal"
              className="absolute bg-theme-color--brand-red inset-0"
            ></div>
          </div>
          <div className="relative">
            <span>soon...</span>
            <div
              data-gsap="heading-reveal"
              className="absolute bg-theme-color--brand-red inset-0"
            ></div>
          </div>
        </Heading>
        <div className="relative">
          <div className="bg-theme-color--brand-red-contrast h-px my-6 w-16 md:mx-auto md:my-8 md:w-20 lg:my-10 lg:w-24 xl:my-12 xl:w-28"></div>
          <div
            data-gsap="line-bottom-reveal"
            className="absolute bg-theme-color--brand-red inset-0"
          ></div>
        </div>
        <div
          data-gsap="link-mail"
          className="opacity-0 text-sm translate-y-4 md:text-base"
        >
          <a href="mailto:office@gloo.rs">
            office@<span className="font-black">gloo</span>.rs
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
