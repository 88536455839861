import React from "react";

const Heading = ({ children }) => {
  return (
    <h1 className="font-black leading-10 text-4xl md:text-5xl md:leading-tight lg:text-6xl xl:text-7xl">
      {children}
    </h1>
  );
};

export default Heading;
