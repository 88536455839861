import React from "react";

const Section = ({ children }) => {
  return (
    <section className="flex flex-col min-h-theme-height--svh-header items-start justify-end px-10 py-12 md:justify-center md:text-center">
      <div className="mx-auto max-w-2xl w-full">{children}</div>
    </section>
  );
};

export default Section;
