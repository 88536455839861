import React from "react";
import Header from "../header/Header";
import Main from "../main/Main";
import Root from "../root/Root";
import { PageContextProvider } from "../../../contexts/PageContextProvider";

function LayoutRootHeaderMain() {
  return (
    <PageContextProvider>
      <Root>
        <Header />
        <Main />
      </Root>
    </PageContextProvider>
  );
}

export default LayoutRootHeaderMain;
