import React from "react";
import { useContext } from "react";
import { PageContext } from "../../../contexts/PageContextProvider";

const Root = ({ children }) => {
  const pageContext = useContext(PageContext);
  const rootRef = pageContext.rootRef;
  return (
    <div
      ref={rootRef}
      className="bg-theme-color--brand-red min-h-theme-height--svh overflow-hidden text-theme-color--brand-red-contrast"
    >
      {children}
    </div>
  );
};

export default Root;
