import React from "react";
import Logo from "./Logo";
import Socials from "./Socials";

const Header = () => {
  return (
    <header
      data-gsap="header"
      className="box-content flex items-center justify-center h-theme-height--svh px-10 py-4"
    >
      <nav className="flex items-center justify-between mx-auto max-w-screen-lg relative w-full">
        <Logo />
        <Socials />
      </nav>
    </header>
  );
};

export default Header;
