import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LayoutRootHeaderMain from "./components/app/layout/LayoutRootHeaderMain";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LayoutRootHeaderMain />}>
          <Route path="" element={<Home />}></Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
