import React from "react";
import Section from "../components/app/section/Section";
import Heading from "../components/app/typography/Heading";

const NotFound = () => {
  return (
    <Section>
      <Heading>Stranica nije pronađena</Heading>
    </Section>
  );
};

export default NotFound;
