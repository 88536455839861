import React, { createContext, useEffect, useRef, useState } from "react";
import gsap from "gsap";

const PageContext = createContext();

const PageContextProvider = ({ children }) => {
  const rootRef = useRef();
  const [gsapTL, setGsapTL] = useState();
  useEffect(() => {
    const body = document.body;
    const root = rootRef.current;
    const gsapTL = gsap.timeline({ defaults: { duration: 0.5 } }, root);

    const rootStyles = getComputedStyle(document.documentElement);
    const headerHeight = rootStyles.getPropertyValue("--theme-height--header");
    gsapTL
      .set(body, { overflow: "hidden" })
      .to("[data-gsap='logo-g']", {
        scale: 1,
        x: "8rem",
        y: 0,
        delay: 0.35,
      })
      .to("[data-gsap='logo-g']", {
        x: 0,
      })
      .to("[data-gsap='logo-o-2']", {
        opacity: 1,
        delay: -0.45,
      })
      .to("[data-gsap='logo-o-1']", {
        opacity: 1,
        delay: -0.4,
      })
      .to("[data-gsap='logo-l']", {
        opacity: 1,
        delay: -0.35,
      })
      .to("[data-gsap='header']", {
        height: headerHeight,
        duration: 0.75,
      })
      .set(body, { overflow: "auto" })
      .to("[data-gsap='logo']", {
        left: 0,
        x: "50%",
        delay: -0.75,
        duration: 0.75,
      })
      .set("[data-gsap='logo']", { position: "relative" })
      .to("[data-gsap='line-top-reveal']", { translateX: "100%" })
      .to("[data-gsap='social-network']", {
        opacity: 1,
        translateX: 0,
        translateY: 0,
        stagger: 0.5,
      })
      .to("[data-gsap='main']", { opacity: 1 });
    setGsapTL(gsapTL);
  }, []);
  return (
    <PageContext.Provider
      value={{
        rootRef: rootRef,
        gsapTL: gsapTL,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageContextProvider };
